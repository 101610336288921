<script>
	import Modal from './Modal.svelte';
	import { onMount } from 'svelte';
	export let showToast = false;
	export let css = '';
	export let timer = false;
	export let onClose = () => {
		showToast = false;
	};
	// snack should close after 5 seconds

	onMount(() => {
		if (timer) {
			const closeTimer = setInterval(() => {
				timer--;
				if (timer <= 0) {
					clearInterval(closeTimer);
					// showToast = false;
					onClose();
				}
			}, 1000);
		}
	});
</script>

<Modal
	bind:show={showToast}
	bgClasses="bg-opacity-20"
	options={{
		from: 'bottom',
		topLevel: true,
		cssText: `background:linear-gradient(153.72deg, #070822 9.02%, #080F34 49.16%, #0A196A 88.26%);bottom: 1rem;
top: unset;
--tw-translate-y: 0;max-width: min(20rem, 95vw);padding:1rem;${css || ''}`
	}}
	on:close={() => {
		onClose();
	}}
>
	<!-- timer circle using Tailwind utility classes and animations -->
	<div class="flex items-center space-x-4">
		{#if timer}
			<div class="animate-pulse -ml-1 mr-3 relative">
				<svg
					class="animate-spin h-6 w-6 text-black"
					xmlns="http://www.w3.org/2000/svg"
					fill="none"
					viewBox="0 0 24 24"
				>
					<circle
						class="opacity-25"
						cx="12"
						cy="12"
						r="10"
						stroke="currentColor"
						stroke-width="4"
					/>
					<path
						class="opacity-75"
						fill="currentColor"
						d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
					/>
				</svg>
				<div class="absolute font-semibold centreInContext" style="font-size:10px;line-height: 1;">
					{timer}
				</div>
			</div>
		{/if}
		<div class="flex-1 space-y-4 py-1">
			<slot />
		</div>
	</div>
</Modal>

<style>
	/* your styles go here */
	@keyframes pulse {
		50% {
			opacity: 0.8;
		}
	}
</style>
