<script>
	import Snack from '../components/Snack.svelte';
	export let showRestart = false;
</script>

<Snack showToast={showRestart}>
	<h4 class="text-center font-semibold text-lg mb-0">Update downloaded</h4>
	<h4 class="text-center font-semibold mb-0">Restart to install?</h4>
	<div class="grid grid-cols-2 gap-4 text-sm">
		<button
			style="background: linear-gradient(
                153.72deg,
                #0a196a 3.26%,
                #1535c9 53%,
                #316fe2 72.24%,
                #387fe1 95.76%
            );"
			on:click={() => window.api?.call('restart').catch((e) => window.api?.call('quitAndRestart'))}
			>Restart</button
		>
		<button
			style="background: linear-gradient(
                153.72deg,
                #0a196a 3.26%,
                #1535c9 53%,
                #316fe2 72.24%,
                #387fe1 95.76%
            );"
			on:click={() => (showRestart = false)}>Later</button
		>
	</div>
</Snack>
